
import React from 'react';
import SearchResultItem from './SearchResultItem';
import { downloadReceipt } from '../service';
import { translate, translateTemplate } from '../../backend/locale';
const { getBrowserLanguage } = require('../util');

export default function SearchResult({
    foundItems,
    companyEmail,
    companyType,
  }) {
    const searchResultItems = foundItems.map((item) => (
        <SearchResultItem
            key={item.id}
            onDownloadClick={()=> (item && downloadReceipt(item.id))}
            {...item}
            companyType={companyType}
        />
    ));
    return (
        <div className='title'>
                { searchResultItems.length > 0 ?
                    <div>
                        <div>{translateTemplate('search_result_found', [searchResultItems.length], getBrowserLanguage())}</div>
                        <div>{searchResultItems}</div>
                    </div>
                    :
                    <div>
                        {companyEmail
                        ?
                        <div dangerouslySetInnerHTML={{ __html: translateTemplate(`search_result_nothing_contact_support_${companyType}`, [companyEmail], getBrowserLanguage()) }} />
                        :
                        translate(`search_result_nothing_${companyType}`, getBrowserLanguage())}
                    </div>
                    
                }
        </div>
    );
  }