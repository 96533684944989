const strings = {
    with: {
        en: 'with',
        es: 'con',
        fr: 'avec',
        pt: 'com',
        it: 'con',
    },
    search_guide_air: {
        en: 'Enter flight details to find your receipt',
        es: 'Ingrese sus detalles de vuelo para buscar su recibo',
        fr: 'Renseignez les détails de votre vol pour avoir votre reçu',
        pt: 'Visita os dados do seu voo para obter o seu recibo',
        it: 'Inserite i dati del vostro volo per ottenere la vostra ricevuta',
    },
    search_guide_rail: {
        en: 'Enter journey details to find your receipt',
        es: 'Ingrese sus detalles de viaje para buscar su recibo',
        fr: 'Renseignez les détails de votre voyage pour avoir votre reçu',
        pt: 'Visita os dados da sua viagem para obter o seu recibo',
        it: 'Inserite i vostri dati di viaggio per ottenere la vostra ricevuta',
    },
    search_payment_method: {
        en: 'Payment method',
        es: 'Método de pago',
        fr: 'Moyen de paiement',
        pt: 'Método de pagamento',
        it: 'Metodo di pagamento',
    },
    search_payment_method_card: {
        en: 'Card/Contactless',
        es: 'Tarjeta/Contactless',
        fr: 'Carte bancaire/Sans contact/Mobile',
        pt: 'Cartão bancário/Contactless/Móvel',
        it: 'Carta di credito/Contactless/Mobile',
    },
    search_payment_method_mobile: {
        en: 'Mobile',
        es: 'Móvil',
        fr: 'Portable',
        pt: 'Telemóvel',
        it: 'Cellulare',
    },
    search_payment_method_maestro: {
        en: 'Maestro/Cash',
        es: 'Maestro/Efectivo',
        fr: 'Espèces',
        pt: 'Dinheiro',
        it: 'Contanti',
    },
    search_departure_date: {
        en: 'Departure Date',
        es: 'Fecha de Ida',
        fr: 'Date de départ',
        pt: 'Data de partida',
        it: 'Data di partenza',
    },
    search_last_4_digits: {
        en: 'Card Number (Last 4 digits)',
        es: 'Numero de Tarjeta (últimos 4 dígitos)',
       fr: 'Numéro de carte (4 derniers chiffres)',
       pt: 'Número do cartão (últimos 4 dígitos)',
       it: 'Numero della carta (ultime 4 cifre)',
    },
    search_journey_number_air: {
        en: 'Flight Number',
        es: 'Numero de Vuelo',
        fr: 'Numéro de vol',
        pt: 'Número do voo',
        it: 'Numero di volo',
    },
    search_journey_number_rail: {
        en: 'Train number',
        es: 'Numero de ruta',
        fr: 'Numéro de train',
        pt: 'Número do comboio',
        it: 'Numero del treno',

    },
    search_seat_number: {
        en: 'Seat Number',
        es: 'Numero de Asiento',
        fr: 'Numéro de siège',
        pt: 'Número do lugar',
        it: 'Numero di posti a sedere',
    },
    btn_find_my_receipts: {
        en: 'Find my receipts',
        es: 'Buscar mis recibos',
        fr: 'Obtenir mon ticket de caisse',
        pt: 'Receber o meu recibo',
        it: 'Ricevere la ricevuta',
    },
    search_result_found: {
        en: (n) => `You have ${n} receipt${n > 1 ? 's' : ''}`,
        es: (n) => `Tienes ${n} recibo${n > 1 ? 's' : ''}`,
        fr: (n) => `Vous avez ${n} ticket${n > 1 ? 's' : ''}`,
        pt: (n) => `You have ${n} receipt${n > 1 ? 's' : ''}`,
        it: (n) => `You have ${n} receipt${n > 1 ? 's' : ''}`,
    },
    search_result_nothing_air: {
        en: 'We could not find a receipt with those flight details. Please try again.',
        es: 'No pudimos encontrar su recibo con esa información de vuelo. Por favor intenta de nuevo.',
        fr: 'Nous n\'avons pas pu trouver de ticket avec ces détails de vol. Veuillez réessayer.',
        pt: 'Não foi possível encontrar um bilhete com estes dados de voo. Por favor, tente novamente.',
        it: 'Non siamo riusciti a trovare un biglietto con questi dettagli di volo. Si prega di riprovare.',
    },
    search_result_nothing_rail: {
        en: 'We could not find a receipt with those journey details. Please try again.',
        es: 'No pudimos encontrar su recibo con esa información de viaje. Por favor intenta de nuevo.',
        fr: 'Nous n\'avons pas pu trouver de ticket avec ces détails de voyage. Veuillez réessayer.', 
        pt: 'Não foi possível encontrar um bilhete com estes dados de viagem. Por favor, tente novamente.',
        it: 'Non siamo riusciti a trovare un biglietto con questi dati di viaggio. Si prega di riprovare.',
    },
    search_result_nothing_contact_support_air: {
        en: (email) => `We could not find a receipt with those flight details. Please contact <a href="mailto:${email}">${email}</a> to find your receipt.`,
        es: (email) => `No pudimos encontrar su recibo con esa información de vuelo. Por favor póngase en contacto con <a href="mailto:${email}">${email}</a> para encontrar su recibo.`,
        fr: (email) => `Nous n'avons pas pu trouver de ticket avec ces données de vol. Veuillez contacter <a href="mailto:${email}">${email}</a> pour retrouver votre ticket.`,
        pt: (email) => `Não encontrámos um bilhete com estes dados de voo. Por favor, contacte <a href="mailto:${email}">${email}</a> para recuperar o seu bilhete.`,
        it: (email) => `Non siamo riusciti a trovare un biglietto con questi dati di volo. Si prega di contattare <a href="mailto:${email}">${email}</a> per trovare il tuo biglietto.`,
    },
    search_result_nothing_contact_support_rail: {
        en: (email) => `We could not find a receipt with those journey details. Please contact <a href="mailto:${email}">${email}</a> to find your receipt.`,
        es: (email) => `No pudimos encontrar su recibo con esa información de viaje. Por favor póngase en contacto con <a href="mailto:${email}">${email}</a> para encontrar su recibo.`,
        fr: (email) => `Nous n'avons pas pu trouver de ticket avec ces données de voyage. Veuillez contacter <a href="mailto:${email}">${email}</a> pour retrouver votre ticket.`,
        pt: (email) => `Não foi possível encontrar um bilhete com estes dados de viagem. Por favor, contacte <a href="mailto:${email}">${email}</a> para recuperar o seu bilhete.`,
        it: (email) => `Non siamo riusciti a trovare un biglietto con questi dettagli di viaggio. Si prega di contattare <a href="mailto:${email}">${email}</a> per trovare il tuo biglietto.`,

    },
    disclaimer_air: {
        en: '*Your receipt will be available 24 hours after your flight.',
        es: '*Su recibo estará disponible 24 horas después de su vuelo.',
        fr: '*Votre ticket sera disponible 24 heures après votre vol.',
        pt: '*O seu bilhete estará disponível 24 horas após o seu voo.',
        it: 'Il biglietto sarà disponibile 24 ore dopo il volo.',

    },
    disclaimer_rail: {
        en: '*If you have trouble downloading your payment receipt please contact our customer service, click <a href="https://ouigo.zendesk.com/hc/es/requests/new">here</a>.',
        es: '*Si tienes problemas para descargar tu recibo o  deseas factura de caje puedes dirigirte a nuestro servicio de atención al cliente, pincha <a href="https://ouigo.zendesk.com/hc/es/requests/new">aquí</a>.',
        fr: '*Si vous rencontrez des difficultés pour télécharger votre ticket veuillez contacter notre service client, cliquez sur <a href=" ">here</a>.',
        pt: '*Se tiver problemas para descarregar o seu bilhete, contacte o nosso serviço de apoio ao cliente, clique em <a href=" ">here</a>.',
        it: '**In caso di problemi con il download del biglietto, contattare il nostro servizio clienti.',

    },
    footer: {
        en: (name) => `A solution by ${name} and AirFi.aero`,
        es: (name) => `Una solución por ${name} y AirFi.aero`,
        fr: (name) => `Une solution de ${name} et AirFi.aero`,
        pt: (name) => `Uma solução para ${name} e AirFi.aero`,
        it: (name) => `Una soluzione per ${name} e AirFi.aero`,
    },
    receipt_type_payment: {
        en: 'Payment',
        es: 'Tipo de Pago',
        fr: 'Moyen de paiement',
        pt: 'Método de pagamento',
        it: 'Metodo di pagamento',
    },
    receipt_type_refund: {
        en: 'Refund',
        es: 'Reembolso',
        fr: 'Remboursement',
        pt: 'Reembolsos',
        it: 'Rimborso',
    },
    receipt_journey_number_air: {
        en: 'Flight number',
        es: 'Numero de Vuelo',
        fr: 'Numéro de vol',
        pt: 'Número do voo',
        it: 'Numero di volo',
    },
    receipt_journey_number_rail: {
        en: 'Train number',
        es: 'Numero de ruta',
        fr: 'Numéro de train',
        pt: 'Número do comboio',
        it: 'Numero del treno',

    },
    receipt_time: {
        en: 'Time',
        es: 'Hora',
        fr: 'Heure',
        pt: 'Tempo',
        it: 'Tempo',
    },
    receipt_amount: {
        en: 'Amount',
        es: 'Cantidad',
        fr: 'Quantité',
        pt: 'Quantidade',
        it: 'Quantità',
    },
    btn_download_pdf: {
        en: '⬇ Download PDF',
        es: '⬇ Descargar PDF',
        fr: '⬇ Télécharger PDF',
        pt: '⬇ Descarregar pdf',
        it: '⬇Scaricare pdf',
    },
    btn_go_back: {
        en: '⬅ Go back',
        es: '⬅ Regresar',
        fr: '⬅ Retour',
        pt: '⬅ Voltar',
        it: '⬅Indietro',

    },
    receipt_pdf_refund: {
        en: 'Refund Invoice',
        es: 'Factura de Reembolso',
        fr: 'Ticket de remboursement',
        pt: 'Bilhete de reembolso',
        it: 'Biglietto di rimborso',
    },
    receipt_pdf_invoice: {
        en: 'Invoice',
        es: 'Factura',
        fr: 'Facture',
        pt: 'Fatura',
        it: 'Fattura',
    },
    receipt_pdf_id: {
        en: 'Purchase ID',
        es: 'Número de Compra',
        fr: 'Numéro de transaction',
        pt: 'Número da transação',
        it: 'Numero di transazione',
    },
    receipt_pdf_number: {
        en: 'Number',
        es: 'Número',
        fr: 'Numéro',
        pt: 'Número',
        it: 'Numero',
    },
    receipt_pdf_tablet_id: {
        en: 'Serial',
        es: 'Serie',
        fr: 'Série',
        pt: 'Série',
        it: 'Serie',
    },
    receipt_pdf_simplified_invoice: {
        en: 'Simplified Invoice',
        es: 'Factura Simplificada',
        fr: 'Ticket de caisse',
        pt: 'Fatura Proforma',
        it: 'Fattura proforma',
    },
    receipt_pdf_date: {
        en: 'Date',
        es: 'Fecha',
        fr: 'Date',
        pt: 'Data',
        it: 'Data',
    },
    receipt_pdf_journey_air: {
        en: 'Flight',
        es: 'Vuelo',
        fr: 'Vol',
        pt: 'Voo',
        it: 'Volo',
    },
    receipt_pdf_journey_rail: {
        en: 'Train number',
        es: 'Numero de ruta',
        fr: 'Numéro de train',
        pt: 'Número do comboio',
        it: 'Numero del treno',
    },
    receipt_pdf_description: {
        en: 'Description',
        es: 'Descripción',
        fr: 'Description',
        pt: 'Descrição',
        it: 'Descrizione',
    },
    receipt_pdf_price: {
        en: 'Price',
        es: 'Precio',
        fr: 'Prix',
        pt: 'Preço',
        it: 'Prezzo',
    },
    receipt_pdf_tax_rate: {
        en: 'Tax Rate',
        es: 'Tasa de Impuesto',
        fr: 'TVA',
        pt: 'IVA',
        it: 'IVA',

    },
    receipt_pdf_tax_added: {
        en: 'Tax Added',
        es: 'Impuesto Agregado',
        fr: 'Taxe Ajoutée',
        pt: 'Imposto adicional',
        it: 'Imposta aggiunta',
    },
    receipt_pdf_quantity: {
        en: 'Quantity',
        es: 'Cantidad',
        fr: 'Quantité',
        pt: 'Quantidade',
        it: 'Quantità',
    },
    receipt_pdf_amount: {
        en: 'Amount',
        es: 'Total',
        fr: 'Montant',
        pt: 'montante',
        it: 'Importo',
    },
    receipt_pdf_total: {
        en: 'Total (tax included)',
        es: 'Total (impuesto incluido)',
        fr: 'Total (TVA inclue)',
        pt: 'Total (incl. IVA)',
        it: 'Totale (IVA inclusa)',
    },
    receipt_pdf_total_tax: {
        en: 'Total tax',
        es: 'Total Impuesto',
        fr: 'TVA Totale',
        pt: 'Total IVA',
        it: 'Totale IVA',
    },
    receipt_pdf_generated_footer: {
        en: (date) => `Generated on ${date}`,
        es: (date)=> `Generada en ${date}`,
        fr: (date)=> `Généré le ${date}`,
        pt: (date)=>  `Gerado em ${date}`,
        it: (date)=> `Generato su ${date}`,
    },
    receipt_pdf_issued_by: {
        en: 'Issued by',
        es: 'Denominación',
        fr: 'Délivré par',
        pt: 'Entregue por',
        it: 'Emesso da',
    },
    receipt_pdf_address: {
        en: 'Address',
        es: 'Dirección',
        fr: 'Adresse',
        pt: 'Morada',
        it: 'Indirizzo',
    },
    receipt_discount: {
        en: 'DISCOUNT',
        es: 'DESCUENTO',
        fr: 'PROMOTION',
        pt: 'PROMOÇÃO',
        it: 'PROMOZIONE',
    },
};

function translate(string, language) {
    let lang = language || 'en';
    return strings[string][lang] || strings[string]['en'];
}

function translateTemplate(string, args, language) {
    let lang = language || 'en';
    return strings[string][lang] ? strings[string][lang](...args) : strings[string]['en'](...args);
}

module.exports = {
    translate,
    translateTemplate,
};