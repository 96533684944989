import React from 'react'

class RadioButton extends React.Component {
    render() {
        return <div style={this.props.style} className='radio-button' onClick={() => this.props.onChange(this.props.id)}>
            <input
                readOnly
                checked={this.props.checked}
                type='radio'
                id={this.props.id}
                value={this.props.label}/>
            <label>{this.props.label}</label>
        </div>
    }
}
export default RadioButton;