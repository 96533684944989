
import React from 'react';
import moment from 'moment';
import Button from './Button';
import { translate } from '../../backend/locale';
const { getBrowserLanguage } = require('../util');

export default function SearchResultItem({
    companyType,
    order_flight_number,
    time,
    amount,
    is_refund,
    onDownloadClick,
  }) {
    const timeFormatted = moment(time).format('MMM DD, HH:mm');
    return (
      <div className='searchResultItem-wrapper'>
        <div className='searchResultItem'>
            <div className='searchResultItem-value' style={{ width: '100%', marginBottom: 25 }}>{is_refund ? translate('receipt_type_refund', getBrowserLanguage()) : translate('receipt_type_payment', getBrowserLanguage())}</div>
            <div className='searchResultItem-value' style={{ width: '60%', textAlign: 'left' }}>{translate(`receipt_journey_number_${companyType}`, getBrowserLanguage())}</div>
            <div className='searchResultItem-value' style={{ width: '40%', textAlign: 'right' }}>{order_flight_number}</div>
            <div className='searchResultItem-value' style={{ width: '25%', textAlign: 'left'  }}>{translate('receipt_time', getBrowserLanguage())}</div>
            <div className='searchResultItem-value' style={{ width: '75%', textAlign: 'right' }}>{timeFormatted}</div>
            <div className='searchResultItem-value' style={{ width: '50%', textAlign: 'left', borderTop: '1px dashed #000', marginTop: 25, paddingTop: 10 }}>{translate('receipt_amount', getBrowserLanguage())}</div>
            <div className='searchResultItem-value' style={{ width: '50%',  textAlign: 'right', borderTop: '1px dashed #000', marginTop: 25, paddingTop: 10 }}>{amount}</div>
        </div>
          <div style={{ marginTop: 35, marginBottom: 25 }}><Button onClick={onDownloadClick} text={translate('btn_download_pdf', getBrowserLanguage())}/></div>
      </div>
    );
  }