import React from 'react'

class RadioButtonGroup extends React.Component {
    render() {
        return <div className='radio-button-group'>
            <div className='radio-group-title'>{this.props.title}</div>
            {this.props.children}
        </div>
    }
}
export default RadioButtonGroup;