
import React from 'react';

export default function Loading(primaryColor) {
    return (
        <div style={{ width: '100%', marginTop: 35 }}>
            <div style={{ borderColor: primaryColor + '52', borderTopColor: primaryColor }} className='loading'>
            </div>
        </div>
    );
  }