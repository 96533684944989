import moment from 'moment';
import download from 'downloadjs';
import { getBrowserLanguage } from './util';

async function getTheme() {
    const result = await fetch('/api/theme');
    const json = await result.json();
    return json;
}

function getReceipts({
    departureDate,
    flightNumber,
    seatNumber,
    lastDigits,
    selectedPaymentMethod,
}) {
    const timeFormatted = moment(departureDate).format('YYYY-MM-DD');
    return new Promise(async (resolve, reject) => {
        grecaptcha.ready(function () {
            grecaptcha.execute('6LdQob4UAAAAACY0zdA8VMR35z2fWr5yvhDrrbgj', { action: 'homepage' }).then(async (recaptcha_token) => {
                const result = await fetch(`/api/order/searchOrder`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        recaptcha_token,
                        order_seat_number: seatNumber,
                        order_flight_number: flightNumber,
                        order_date: timeFormatted,
                        card_last_digits: lastDigits,
                        selected_payment_method: selectedPaymentMethod,
                    }),
                });
                const json = await result.json();
                resolve(json && json.data);
            }).catch(reject);
        });
    })
}

function downloadReceipt(orderId) {
    return new Promise(async (resolve, reject) => {
        grecaptcha.ready(function () {
            grecaptcha.execute('6LdQob4UAAAAACY0zdA8VMR35z2fWr5yvhDrrbgj', { action: 'homepage' }).then(async (recaptcha_token) => {
                const result = await fetch(`/api/receipt/buildReceipt`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        recaptcha_token,
                        order_id: orderId,
                        user_language: getBrowserLanguage(),
                    }),
                });
                if (result.status !== 200) {
                    return reject();
                }
                const blob = await result.blob();
                download(blob, orderId);
            }).catch(reject);
        });
    });
}

export {
    getTheme,
    getReceipts,
    downloadReceipt,
}