import React from 'react';

class Button extends React.Component {
    constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClick();
  }
  render() {
    return (
      <button
        disabled={this.props.disabled}
        onClick={this.onClick}
      >
          <div style={{ 
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <div>{this.props.text}</div>
            { this.props.icon &&
              <div style={{ backgroundImage: `url(${this.props.icon})`, backgroundSize: 'cover', width: 22, height: 22, marginLeft: 6 }}/>
            }
          </div>
      </button>
    );
  }
}

export default Button;